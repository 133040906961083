@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css";
@import "https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
@font-face{font-family:Poppins-Regular;src:url(/fonts/poppins/Poppins-Regular.ttf)}
@font-face{font-family:Poppins-Medium;src:url(/fonts/poppins/Poppins-Medium.ttf)}
@font-face{font-family:Poppins-Bold;src:url(/fonts/poppins/Poppins-Bold.ttf)}
@font-face{font-family:Poppins-SemiBold;src:url(/fonts/poppins/Poppins-SemiBold.ttf)}
@font-face{font-family:Montserrat-Bold;src:url(/fonts/montserrat/Montserrat-Bold.ttf)}
@font-face{font-family:Montserrat-SemiBold;src:url(/fonts/montserrat/Montserrat-SemiBold.ttf)}
@font-face{font-family:Montserrat-Regular;src:url(/fonts/montserrat/Montserrat-Regular.ttf)}

*{margin:0;}
.clearfix{clear:both;}
.label1{color: #00111a;
  font-weight: bold;
  float: left;
  max-width: 190px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 22px;
  margin: 0;}
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #fff;
    border-bottom: solid 2px #1f2744;
    border-radius: 0;
    color:#1f2744;
    font-weight: 500;
}
.nav-pills .nav-link {color:#494b4d;}
.cred{color:#eb322e; font-weight: 500;}
.cblue{  color:#1f2744;
  font-weight: 500;}
  .cbluesave{ color:#1f2744;
    font-weight: 500;background: transparent;outline: none;border: none;}
    .cbluesave:hover,.cbluesave:focus{ color:#1f2744;
      font-weight: 500;background: transparent; text-decoration: underline;outline: none;border: none;}
  .valid {
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='green' class='bi bi-check-lg' viewBox='0 0 16 16'%3E%3Cpath d='M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z'/%3E%3C/svg%3E"); */
  background-repeat: no-repeat;background-position:98% 50%;
  border:solid 1px green;
}

.visibleMO{
 visibility: hidden;
}

.has-valid .form-control {
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='green' class='bi bi-check-lg' viewBox='0 0 16 16'%3E%3Cpath d='M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z'/%3E%3C/svg%3E") !important; */
  background-repeat: no-repeat;background-position:98% 50%;
  border:solid 1px green;
}

.has-error .form-control {
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='red' class='bi bi-x-lg' viewBox='0 0 16 16'%3E%3Cpath d='M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");background-repeat: no-repeat;background-position:right; */
  background-repeat: no-repeat;background-position:98% 50%;
  border:solid 1px red;
}

.invalid {
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='red' class='bi bi-x-lg' viewBox='0 0 16 16'%3E%3Cpath d='M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");background-repeat: no-repeat;background-position:right; */
  background-repeat: no-repeat;background-position:98% 50%;
  border:solid 1px red;
}

.he-1{height:10px;}
.options{border:solid 1px #b6b6b6;display: none !important;}


.fileupload{

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='red' class='bi bi-x-lg' viewBox='0 0 16 16'%3E%3Cpath d='M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");background-repeat: no-repeat;background-position:right;
  background-repeat: no-repeat;background-position:98% 50%;
  border:solid 1px red;
}

.page-item.active .page-link .sr-only{display: none !important;}
.modal-header .close .sr-only{display: none !important;background: #fff;outline: none;border: none !important;font-size: 30px;}
.filtercontainer{position: relative;
  padding: 15px;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 10%);
  -webkit-box-shadow: 0px 2px 2px rgb(0 0 0 / 10%);
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
position: sticky;
height: auto;
}
.filter-list{list-style-type: none;padding:0;}
.filter-list li{list-style-type: none;}
.filter-list li a{list-style-type: none;font-size: 12px;color: #000;}
.variablebox{max-height: 120px;min-height: 0;
      overflow-y: scroll;}
.backgroundimage{background-size: cover !important;background-repeat: no-repeat;}

.img-responsive{width:100%;display: inline-block;}

.backgroundimage{
  padding-top: 57.14% !important;
  position: relative;
  background-position: center center;
}
.cardheight{
  position: absolute !important;
  top: 0%;
  left: 0%;
  bottom: 0%;
  right: 0%; 
}
/* mobile responsive */
@media only screen and (min-width: 280px) and (max-width: 500px) {
  .carousel-caption{display: none;}
  .hidemobile{display: none;}
  .mobiletabs {outline: none;
        position: fixed;
        width: 100%;
        background: #fff;
        z-index: 100;
        bottom: 2px;
        left:12px;
      }
  .mobiletabs .nav-pills .nav-link,.mobiletabs .nav-link{outline: none;padding: .5rem ;}
  .cardcontainer{order:1;}
  .tabsidebar{order:2;}
  /* .cardheight{min-height: 200px !important;max-height: 200px;} */
    }


/* mobile responsive */

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}
footer {
  /* background: #212529; */
  border-top: solid 1px #dfdfdf;
  background: none !important;  
}


/* pricing */

.relative{ 
    position: relative;
}
.absolute{ 
    position: absolute;
}
.fixed{ 
    position: fixed;
}

.pricing-table-container{
  padding:50px 0px;
}
.description{
  padding:15px 0px;
}
.description a{
  padding:10px;
  font-size:13px;
  display:block;
  font-weight:bold;
  border-bottom:1px solid #DDD;
}
.description a.active{
  background-color:#FFF;
  padding-left:25px;
}

#imgwatermark{
  position: absolute;
    z-index: 999;
    color: #ffffffa8;
    left: 35%;
    /* top: 40%; */
    font-size: 50px;
}
.frontwatermark{
  top: 20%;
}
.backwatermark{
  bottom: 25%;
}
.text-wrapp{
  overflow-wrap: anywhere;
}

@media (min-width: 576px){
  #imgpreviewmodel.modal-dialog {
    max-width: fit-content;
}
}

/* DEMO 01 */
.pricing-table-3{
  background-color:#FFF;
  margin:15px auto;
  box-shadow:0px 0px 25px rgba(0,0,0,0.1);
  max-width:300px;
  border-radius:0px 10px 0px 10px;
  overflow:hidden;
  position:relative;
  min-height:250px;
  transition:all ease-in-out 0.25s;
}
.pricing-table-3:hover{
  transform:scale(1.1,1.1);
  cursor:pointer;
}

.pricing-table-3.basic .price{
  background-color:#eb322e;
  color:#FFF;
}
.pricing-table-3.premium .price{
  background-color:#c71612;
  color:#FFF;
}
.pricing-table-3.business .price{
  background-color:#eb322e;
  color:#FFF;
}

.pricing-table-3 .pricing-table-header{
  background-color:#212121;
  color:#FFF;
  padding:20px 0px 0px 20px;
  position:absolute;
  z-index:5;
}
.pricing-table-3 .pricing-table-header p{
  font-size:12px;
  opacity:0.7;
}
.pricing-table-3 .pricing-table-header::before{
  content:"";
  position:absolute;
  left:-50px;
  right:-180px;
  height:150px;
  top:-50px;
  background-color:#1f2744;
  z-index:-1;
  transform:rotate(-20deg)
}

.pricing-table-3 .price{
  position:absolute;
  top:0px;
  text-align:right;
  padding:110px 20px 0px 0px;
  right:0px;
  left:0px;
  font-size:20px;
  z-index:4;
}
.pricing-table-3 .price::before{
  content:"";
  position:absolute;
  left:0px;
  right:0px;
  height:100px;
  bottom:-25px;
  background-color:inherit;
  transform:skewY(10deg);
  z-index:-1;
  box-shadow:0px 5px 0px 5px rgb(31 39 68 / 73%);
}


.pricing-table-3 .pricing-body{
  padding:20px;
  padding-top:200px;  
}
.pricing-table-3 .pricing-table-ul li{
  color:rgba(0,0,0,0.7);
  font-size:13px;
  padding:10px;
  border-bottom:1px solid rgba(0,0,0,0.1);
}
.pricing-table-3 .pricing-table-ul .fa{
  margin-right:10px;
}
.pricing-table-3.basic .pricing-table-ul .fa{
  color:#28b6f6;
}
.pricing-table-3.premium .pricing-table-ul .fa{
  color:#ff9f00;
}
.pricing-table-3.business .pricing-table-ul .fa{
  color:#c3185c;
}
.pricing-table-3 .view-more{
  margin:10px 22px;
  display:block;
  text-align:center;
  background-color:#1f2744;
  padding:10px 0px;
  text-decoration:none;
  border-radius: 3px;
  color:#FFF;
}

/*//////////////////////////////////////////////////////////////////
[ login ]*/
.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;  
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
}


.wrap-login100 {
  width: 100%;
  background: #fff;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row-reverse;

}

/*==================================================================
[ login more ]*/
.login100-more {
  width: calc(100% - 560px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

.login100-more::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.1);
}



/*==================================================================
[ Form ]*/

.login100-form {
  width: 560px;
  min-height: 100vh;
  display: block;
  background-color: #f7f7f7;
  padding: 173px 55px 55px 55px;
}

.login100-form-title {
  width: 100%;
  display: block;
  font-family: Poppins-Regular;
  font-size: 30px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
}



/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
  height: 50px;
  position: relative;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
}

.label-input100 {
  font-family: Montserrat-Regular;
  font-size: 18px;
  color: #999999;
  line-height: 1.2;

  display: block;
  position: absolute;
  pointer-events: none;
  width: 100%;
  padding-left: 24px;
  left: 0;
  top: 30px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100 {
  display: block;
  width: 100%;
  background: transparent;
  font-family: Montserrat-Regular;
  font-size: 15px;
  color: #555555;
  line-height: 1.2;
  padding: 0 10px 5px 10px;
  outline: none;
  border: none;
}

input.input100 {
  height: 100%;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

/*---------------------------------------------*/

.focus-input100 {
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  pointer-events: none;
  border: 1px solid #6675df;
  border-radius: 10px;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  -webkit-transform: scaleX(1.1) scaleY(1.3);
  -moz-transform: scaleX(1.1) scaleY(1.3);
  -ms-transform: scaleX(1.1) scaleY(1.3);
  -o-transform: scaleX(1.1) scaleY(1.3);
  transform: scaleX(1.1) scaleY(1.3);
}

.input100:focus + .focus-input100 {
  visibility: visible;
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.eff-focus-selection {
  visibility: visible;
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.input100:focus {
  height: 48px;
}

.input100:focus + .focus-input100 + .label-input100 {
  top: 9px;
  font-size: 13px;
}

.has-val {
  height: 25px !important;
}

.has-val + .focus-input100 + .label-input100 {
  top: 7px;
  font-size: 13px;
  padding-left:10px;
}

/*==================================================================
[ Restyle Checkbox ]*/

.input-checkbox100 {
  display: none;
}

.label-checkbox100 {
  font-family: Poppins-Regular;
  font-size: 13px;
  color: #999999;
  line-height: 1.4;

  display:inline-block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
}

.txt1 {
  font-family: Montserrat-Regular;
  font-size: 13px;
  line-height: 1.4;
  color: #555;
}

.label-checkbox100::before {
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 13px;
  color: transparent;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background: #fff;
  border: 1px solid #6675df;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.input-checkbox100:checked + .label-checkbox100::before {
  color: #6675df;
}


/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.login100-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  background: #6675df;

  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 1px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  outline: none;
  border:none;
}

.login100-form-btn:hover {
  background: #333333;
}



/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 992px) {
  .login100-form {
    width: 50%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .login100-more {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .login100-form {
    width: 100%;
  }

  .login100-more {
    display: none;
  }
}

@media (max-width: 576px) {
  .login100-form {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 70px;
  }
  .visibleMO{
    visibility:visible;
   }
}


/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  z-index: 100;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;

  font-family: Poppins-Regular;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f12a";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  z-index: 110;
  color: #c80000;
  font-size: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 18px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #f7f7f7 inset !important
}

/*==================================================================
[ Social ]*/

.login100-form-social{text-align:center;}
.login100-form-social-item {
  width: 36px;
  height: 36px;
  font-size: 18px;
  color: #fff;
  border-radius: 50%;
  padding:5px 9px 9px 9px;
  display: inline-block;
  text-align:center;
  margin: 5px;
}
.login100-form-social-item:hover {
  background: #333333;
  color: #fff;
}

.bg1{background:#1f2744;}
.bg2{background:#28b6f6;}


/* register */

.border-md {
  border-width: 2px;
}

.btn-facebook {
  background: #405D9D;
  border: none;
}

.btn-facebook:hover, .btn-facebook:focus {
  background: #314879;
}

.btn-twitter {
  background: #42AEEC;
  border: none;
}

.btn-twitter:hover, .btn-twitter:focus {
  background: #1799e4;
}


.regi{border-width: 2px;
  padding-left: 1.5rem  !important;
  padding-right: 1.5rem  !important;
  border-right:0!important;
  background-color:#fff!important;
  display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    height: 100%;
    border-right: none !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.copy{color:#eb322e !important;text-decoration:none;font-weight: bold;}
footer li a{color:rgb(31, 39, 68) !important;text-decoration:none;}
.cblue{color:rgb(31, 39, 68);}
.bgblue{background:rgb(31, 39, 68);border-color: rgb(31, 39, 68);}

.citem1{padding:40px;
      background-color: #fff;
      border: 1px solid #eaf0f6;
      margin: 20px;}
      .contact-box-title{font-weight: 600;
        color: #33475b;margin-bottom: 24px;font-size: 20px;}
        .contactp{    color: #33475b;margin-bottom: 24px;font-size: 20px;}
      .btn1{padding: 0.9em 1.388em;
            font-size: .9375rem;
            min-width: 8.555rem;
            background-color: #ff7a59;
            color: #fff;
            border: 1px solid #ff7a59;
          }

          .contact-form-btn{

            width: 100%;
            height: 50px;
            border-radius: 25px;
            background: #1f2744;
            font-family: Montserrat-Bold;
            font-size: 15px;
            line-height: 1.5;
            color: #fff;
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 25px;
            -webkit-transition: all 0.4s;
            -o-transition: all 0.4s;
            -moz-transition: all 0.4s;
            transition: all 0.4s;
            border:none !important;
          }

          .signup-btn1{

            width: 100%;
            height: 50px;
            border-radius: 5px;
            background: #1f2744;
            font-family: Montserrat-Bold;
            font-size: 15px;
            line-height: 1.5;
            color: #fff;
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 25px;
            -webkit-transition: all 0.4s;
            -o-transition: all 0.4s;
            -moz-transition: all 0.4s;
            transition: all 0.4s;
            border:none !important;
          }


.cart {
  background-color: #fff;
  padding: 4vh 5vh;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 1rem
}

@media(max-width:767px) {
  .cart {
      padding: 4vh;
      border-bottom-left-radius: unset;
      border-top-right-radius: 1rem
  }
}

.summary {
  background-color: #ddd;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 4vh;
  color: rgb(65, 65, 65)
}

@media(max-width:767px) {
  .summary {
      border-top-right-radius: unset;
      border-bottom-left-radius: 1rem
  }
}

.summary .col-2 {
  padding: 0
}

.summary .col-10 {
  padding: 0
}
.select1{
  border: 1px solid rgba(0, 0, 0, 0.137);
  padding: 1.5vh 1vh;
  margin-bottom: 4vh;
  outline: none;
  width: 100%;
  background-color: rgb(247, 247, 247);
}
.back-to-shop {
  margin-top: 4.5rem;
  text-decoration:none;
  font-weight: bold;
}
.back-to-shop a,.qty {
  font-weight: bold;
  color: #000;
  margin:3px ;
  text-decoration:none;
}
.qty{
  padding: 1px 5px;
  background: #c3c3c3;
  border-radius: 10px;
}
.minumqty{
  padding: 1px 7px;
}
a.qty:hover{
  color: #ffffff;
  background: #198754 !important;
}
.error{border:solid 1px red;}
.valid{border:solid 1px #ccc;}

.toast-header button{float: right;color: #fff;border:none;background: transparent;font-size: 22px;text-align: right;}
.toast{right: 20px;
      position: fixed;
      z-index: 100;
      bottom: 20px;}
      .toast-body{color: #fff;}

.handle{cursor: pointer;}
.react-draggable{display:inline-block;position: absolute;}
.react-draggable-dragging{border:dashed 1px #000 !important;}
.btn-light.active{background: #000 !important;color: #fff!important;}
