body {
    font-size: .875rem;
  }
  
  .feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
  }
  
  /*
   * Sidebar
   */
  
  .sidebar {
    position: fixed;
    top: 0;
    /* rtl:raw:
    right: 0;
    */
    bottom: 0;
    /* rtl:remove */
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 48px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  }
  
  @media (max-width: 767.98px) {
    .sidebar {
      top: 5rem;
    }
  }
  
  .sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  }
  
  .sidebar .nav-link {
    font-weight: 500;
    color: #333;
  }
  
  .sidebar .nav-link .feather {
    margin-right: 4px;
    color: #727272;
  }
  
  .sidebar .nav-link.active {
    color: #2470dc;
  }
  
  .sidebar .nav-link:hover .feather,
  .sidebar .nav-link.active .feather {
    color: inherit;
  }
  
  .sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
  }
  
  /*
   * Navbar
   */
  
  .navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, .25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
  }
  
  .navbar .navbar-toggler {
    top: .25rem;
    right: 1rem;
  }
  
  .navbar .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
  }
  
  .form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
  }
  
  .form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
  }
  .green{background: green;}
  .red{background: red;}
  .face{width: 30px;height: 29px;border-radius: 100%;border-radius: 4px;margin-right: 5px;}


.form-control:focus {
  box-shadow: none;
  border-color: #BA68C8
}

.profile-button {
  background: rgb(99, 39, 120);
  box-shadow: none;
  border: none
}

.profile-button:hover {
  background: #682773
}

.profile-button:focus {
  background: #682773;
  box-shadow: none
}

.profile-button:active {
  background: #682773;
  box-shadow: none
}

.back:hover {
  color: #682773;
  cursor: pointer
}

.labels {
  font-size: 11px
}

.add-experience:hover {
  background: #BA68C8;
  color: #fff;
  cursor: pointer;
  border: solid 1px #BA68C8
}

.card-1 {
  box-shadow: 2px 2px 10px 0px rgb(190, 108, 170)
}
.bold {
  font-weight: 500
}

.change-color {
  color: #AB47BC !important
}

.card-2 {
  box-shadow: 1px 1px 3px 0px rgb(112, 115, 139)
}

.fa-circle.active {
  font-size: 8px;
  color: #AB47BC
}

.fa-circle {
  font-size: 8px;
  color: #aaa
}

.rounded {
  border-radius: 2.25rem !important
}

.progress-bar {
  background-color: #AB47BC !important
}

.progress {
  height: 5px !important;
  margin-bottom: 0
}

.invoice {
  position: relative;
  top: -70px
}

.Glasses {
  position: relative;
  top: -12px !important
}

.card-footer {
  background-color: #AB47BC;
  color: #fff
}

h2 {
  color: rgb(78, 0, 92);
  letter-spacing: 2px !important
}

.display-3 {
  font-weight: 500 !important
}

@media (max-width: 479px) {
  .invoice {
      position: relative;
      top: 7px
  }

  .border-line {
      border-right: 0px solid rgb(226, 206, 226) !important
  }
}

@media (max-width: 700px) {
  h2 {
      color: rgb(78, 0, 92);
      font-size: 17px
  }

  .display-3 {
      font-size: 28px;
      font-weight: 500 !important
  }
}

.card-footer small {
  letter-spacing: 7px !important;
  font-size: 12px
}

.border-line {
  border-right: 1px solid rgb(226, 206, 226)
}